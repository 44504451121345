
import { defineComponent, onMounted, reactive } from "vue";
import ArticleSummary from "@/components/parts/ArticleSummary.vue";
import { getSearch } from "@/components/firebaseOperations";
import { useRouter } from "vue-router";
import InfiniteScroll from "infinite-loading-vue3";
import constants from "@/components/constants";
import { clickedProfile } from "@/components/aboutArticleFunc";

export default defineComponent({
  components: {
    ArticleSummary,
    InfiniteScroll,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      searchText: "",
      candidates: [] as any[],
      selectedSection: "articles",
      articles: {
        docs: new Map(),
      } as any,
      profiles: {
        docs: new Map(),
      } as any,
      notFoundImageIndex: 0,
      notFound: false,
      infiniteScrollNoResult: false,
      infiniteScrollMessage: "",
      error: "",
    });

    /// リセット
    const reset = () => {
      state.articles = {
        docs: new Map(),
      };
      state.profiles = {
        docs: new Map(),
      };
    };

    /// セクションを切り替えた時に実行
    const selectedSection = (text: string) => {
      reset();
      state.searchText = "";
      state.notFound = false;

      const tags = document.getElementById("search-select-sections");
      state.selectedSection = text;
      if (text == "articles") {
        // 記事セクションが選択された場合
        (tags?.firstChild as any).setAttribute("id", "search-selected-section"); // 記事セクションタイトルの色を変える
        (tags?.lastChild as any).removeAttribute("id");
      } else {
        (tags?.firstChild as any).removeAttribute("id");
        (tags?.lastChild as any).setAttribute("id", "search-selected-section");
      }
    };

    /// 検索を行った時に実行
    const search = async () => {
      state.error = "";
      const cleansingText: string = state.searchText.trim();
      if (cleansingText.length > 0) {
        state.candidates = cleansingText.toLowerCase().split(" ");
        if (state.candidates.length <= 5) {
          // 文字が入力された状態で検索実行された場合
          const docAndLastSnapshot = await getSearch(
            state.selectedSection,
            state.candidates,
            null
          );

          if (docAndLastSnapshot.docs.size > 0) {
            // ヒットした場合
            reset();
            state.infiniteScrollNoResult = false;
            if (state.selectedSection === "articles") {
              state.articles = docAndLastSnapshot;
            } else {
              state.profiles = docAndLastSnapshot;
            }
            state.notFound = false;
          } else {
            state.notFound = true;
            state.notFoundImageIndex = Math.floor(Math.random() * 2); // not foundの場合に表示する画像を、ランダムで切り替えるためのインデックスの値を、ランダムに代入する(0 or 1)
          }
        } else {
          state.error = "これ以上指定できません";
        }
      }
    };

    onMounted(() => {
      document.getElementById("search-input")?.focus(); // ページが開かれた時、入力欄をフォーカスする
    });

    /// 最後の要素まで到達した時に実行
    const infiniteHandler = async () => {
      let currentLastSnapshot: any;
      let docs: any;
      if (state.selectedSection === "articles") {
        currentLastSnapshot = state.articles.lastDocSnapshot;
        docs = state.articles.docs;
      } else {
        currentLastSnapshot = state.profiles.lastDocSnapshot;
        docs = state.profiles.docs;
      }

      if (
        docs.size >= constants.UPDATE_BASE_COUNT &&
        !state.infiniteScrollNoResult
      ) {
        // 更新する必要がある場合
        const docAndLastSnapshot = await getSearch(
          state.selectedSection,
          state.candidates,
          currentLastSnapshot
        );

        if (docAndLastSnapshot.docs.size !== 0) {
          // 更新対象のドキュメントがあった場合
          for (const article of docAndLastSnapshot.docs.values()) {
            docs.set(article.id, article);
          }

          currentLastSnapshot = docAndLastSnapshot.lastDocSnapshot;
        } else {
          state.infiniteScrollNoResult = true;
        }
      }
    };

    return {
      clickedProfile,
      router,
      state,
      search,
      selectedSection,
      infiniteHandler,
    };
  },
});
