import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1126b2da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "search" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_SearchPage = _resolveComponent("SearchPage")!
  const _component_BottomNavigation = _resolveComponent("BottomNavigation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode(_component_SearchPage),
    _createVNode(_component_BottomNavigation)
  ]))
}